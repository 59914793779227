<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>WebUI测试报告</el-breadcrumb-item>
      <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="report-header" slot="header">
        <div>
          <el-button type="warning" icon="el-icon-caret-right" @click="handleAdd">新增任务</el-button>
        </div>
        <el-form :inline="true" :model="reportForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="reportForm.reportName" placeholder="输入报告名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
        label="序号"
        type="index"
        width="50">
        </el-table-column>
        <el-table-column
        prop="id"
        label="ID"
        width="50">
        </el-table-column>
        <el-table-column
          prop="report_id"
          label="报告ID"
          width="180">
          <template slot-scope="scope">
            <el-link type="primary" href="#/webUIReport/info" :underline="false" style="font-size: 12px;" @click.native="handleSetReportInfo(scope.row)">
                {{scope.row.report_id}}
            </el-link>
            <!-- <router-link type="primary" :to="{ path: 'report/info' }" style="font-size: 12px;text-decoration: none;"> -->
              <!-- {{scope.row.report_id}}
            </router-link> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="report_name"
          label="报告名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="test_count"
          label="测试数量">
        </el-table-column>
        <el-table-column
          prop="pass_count"
          label="通过数量">
        </el-table-column>
        <el-table-column
          prop="total_time"
          label="执行时长">
        </el-table-column>
        <el-table-column
          prop="com_from"
          label="执行类型">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间"
          width="150">
        </el-table-column>
        <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button round type="warning" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button round type="danger" @click="handleDel(scope.row)">删除</el-button>
          <!-- <el-button round type="info" @click="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button> -->
        </template>
      </el-table-column>
      </el-table>
    </el-card>

    <!-- 环境列表分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

  </div>
</template>

<script>
import { QueryReportList } from '@/services/autoReport.js'
export default {
  name: 'WebUIReport',
  data () {
    return {
      reportForm: {
        reportName: ''
      },
      tableData: [],
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      }
    }
  },
  created () {
    this.loadReportList()
  },
  methods: {
    async loadReportList () {
      const queryObj = {
        query_type: 'query_list',
        report_type: '2',
        size: this.pageInfo.size,
        page: this.pageInfo.currentPage
      }
      const { data: res } = await QueryReportList(queryObj)
      console.log(res, '<--报告信息')
      if (res.status === 200) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    handleAdd () {
      console.log('2')
    },
    handleSearch () {
      console.log('3')
    },
    handleEdit () {
      console.log('4')
    },
    handleDel () {
      console.log('5')
    },
    // 报告信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadReportList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadReportList()
      console.log(`当前页: ${val}`)
    },
    getReportInfo () {
      console.log('点击效果')
    },
    handleSetReportInfo (row) {
      console.log(row, '<-- set 测试报告')
      // const reportInfo = Object.entries(row).reduce((acc, [key, value]) => {
      // acc[key] = value;
      // return acc;
      // }, {})
      // console.log(reportInfo, '<---转化后的最终缓存数据')
      this.$store.commit('saveReportInfo', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.report-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-form-inline{
  height: 40px;
}
.el-pagination {
    padding: 0px 0;
  }
</style>
